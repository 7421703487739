import React from "react";
import "./Loader.css";
import * as IMAGES from "../../Assets/BaseImages";
class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="loader-container">
        <div className="loader" id="spinner">
          <img
            src={IMAGES.BASE_LOGO}
            className="central-icon"
            alt="Food Loader"
          />
        </div>
        {/* <div className="loader-content">
          Please wait while we are baking the happiness for you.
        </div> */}
      </div>
    );
  };
}

export default Loader;
