import React, { Suspense, lazy } from "react";
import "./App.css";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Loader from "./Util/Loader/Loader";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "./Dashboard/Dashboard")
);

const AboutUs = lazy(() =>
  import(/* webpackChunkName: "About-Us" */ "./AboutUs/AboutUs")
);

const Blog = lazy(() => import(/* webpackChunkName: "Blog" */ "./Blog/Blog"));

const BlogContent = lazy(() =>
  import(/* webpackChunkName: "BlogContent" */ "./BlogContent/BlogContent")
);

const Courses = lazy(() =>
  import(/* webpackChunkName: "Courses" */ "./Courses/Courses")
);

const ContactUs = lazy(() =>
  import(/* webpackChunkName: "Contact-Us" */ "./Contact-Us/ContactUs")
);

const FAQ = lazy(() => import(/* webpackChunkName: "FAQ" */ "./FAQ/FAQ"));

const Events = lazy(() =>
  import(/* webpackChunkName: "Events" */ "./Events/Events")
);

const Seminar = lazy(() =>
  import(/* webpackChunkName: "Seminar" */ "./Seminar/Seminar")
);

const Testimonials = lazy(() =>
  import(/* webpackChunkName: "Testimonials" */ "./Testimonials/Testimonials")
);

const Gallery = lazy(() =>
  import(/*webpackChunkName: "Gallery" */ "./Gallery/Gallery")
);

const NotFound = lazy(() =>
  import(/* webpackChunkName: "404" */ "./NotFound/NotFound")
);

const Pages = lazy(() =>
  import(/* webpackChunkName: "Pages" */ "./Pages/Pages")
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffb300",
    },
    secondary: {
      main: "#121212",
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.customHistory = createBrowserHistory();
  }

  render = () => {
    return (
      // <div className="App">
      //   {/* <video autoPlay muted loop id="myVideo">
      //     <source src="./video1.mp4" type="video/mp4" />
      //     Your browser does not support HTML5 video.
      //   </video> */}
      // </div>
      <ThemeProvider theme={theme}>
        <Router history={this.customHistory}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/blog" component={Blog} />
              <Route path="/blog-content/:id" component={BlogContent} />
              <Route path="/courses" component={Courses} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/faq" component={FAQ} />
              <Route path="/events" component={Events} />
              <Route path="/seminar/:id" component={Seminar} />
              <Route path="/testimonials" component={Testimonials} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/pages/:pageType" component={Pages} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    );
  };
}

export default App;
